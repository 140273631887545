import {
  Typography,
  TableContainer,
  Paper,
  Box,
  Tabs,
  Tab,
  Container,
  TextField,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { ProposalTable } from "../components/ProposalTable";
import {
  selectConnectivity,
  selectProjectID,
  selectValue,
  selectSearch,
  setProjectID,
  setValue,
  setSearch,
  setTargetPkg,
  openSpecificPackageModal,
  openUpgradeCategoryModal,
  openCategoryMenu,
  closeCategoryMenu,
  selectCategoryMenuOpen,
  setTargetCategory,
  selectTargetCategory,
  fetchProposals,
  selectProposals,
  fetchPackageSearchResults,
  setTargetRepoID,
  searchForPackageByIssuePackageName,
} from "../features/proposalListSlice";
import { listComponentIssuesByVulnID, ComponentIssue } from "../features/componentDetailSlice";
import useProjectId from "../hooks/useProjectId";
import { RepoList } from "../components/RepoTable";
import { withProjectSelector } from "../components/WithProjectBodyWrapper";
import PackageAutocomplete from "../components/PackageAutocomplete";
import { TargetCategory } from "../features/proposalListSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { fetchRepoList, selectRepos } from "../features/repoListSlice";
import { SourceRepoPackage } from "../features/repoDetailSlice";
import { DialogTriggerPackageAnalysis } from "../components/DialogTriggerPackageAnalysis";
import { DialogTriggerCategoryAnalysis } from "../components/DialogTriggerCategoryAnalysis";
import { ComponentIssueState } from "../pb/edgebit/platform/v1alpha/platform_pb";

export const ProposalList = withProjectSelector("Proposals", (props: { projectId: string }) => {
  const connectivity = useAppSelector(selectConnectivity);
  const value = useAppSelector(selectValue);
  const projectId = useAppSelector(selectProjectID);
  const search = useAppSelector(selectSearch);
  const targetCategory = useAppSelector(selectTargetCategory);
  const categoryMenuOpen = useAppSelector(selectCategoryMenuOpen);
  const proposals = useAppSelector(selectProposals);
  const dispatch = useAppDispatch();
  const anchorRef = useRef<HTMLDivElement>(null);
  const repos = useAppSelector(selectRepos);
  const pkgSearchResults = useAppSelector((state) => state.proposalList.searchResults);
  const issueSearchResults = useAppSelector((state) => state.component.issues);
  const issueStateFilter = [ComponentIssueState.OPEN];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setValue(newValue));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchString = e.target.value;
    dispatch(setSearch(searchString));

    dispatch(listComponentIssuesByVulnID({ projectId, search: searchString, states: issueStateFilter }));
    dispatch(fetchPackageSearchResults({ projectId, query: searchString }));
  };

  const handleTargetPkg = (target?: SourceRepoPackage, branch?: string | undefined) => {
    dispatch(setTargetPkg(target));
    handleTargetCategory({ upgradeCategory: "requested", branch: branch });
    dispatch(openSpecificPackageModal());
  };

  const handleIssuePkg = (issue?: ComponentIssue) => {
    dispatch(searchForPackageByIssuePackageName({ projectId, packageName: issue?.packageName || "" }));
    handleTargetCategory({ upgradeCategory: "requested" });
    dispatch(openSpecificPackageModal());
  };

  const handleTargetCategory = useCallback(
    (updatedProps: TargetCategory) => {
      let mergedProps = { ...targetCategory, ...updatedProps };
      dispatch(setTargetCategory(mergedProps));
    },
    [targetCategory, dispatch],
  );

  const handleSpecificPackageModalOpen = (type: string) => {
    handleTargetCategory({ upgradeCategory: type });
    dispatch(openSpecificPackageModal());
  };

  const handleCloseCategoryMenu = () => {
    dispatch(closeCategoryMenu());
  };

  const handleOpenCateoryMenu = () => {
    dispatch(setSearch(""));
    dispatch(openCategoryMenu());
  };

  const handleUpgradeCategoryModalOpen = (type: string) => {
    handleTargetCategory({ upgradeCategory: type });
    dispatch(openUpgradeCategoryModal());
  };

  useProjectId((projectId: string) => {
    dispatch(setProjectID(projectId));
    dispatch(fetchRepoList({ projectId: projectId }));
    dispatch(fetchProposals({ projectId: projectId }));
  });

  useEffect(() => {
    if (repos && repos.length > 0 && !targetCategory) {
      // todo: filter by repos that are enabled, once we track that
      let firstRepo = repos?.[0];
      if (firstRepo.details && firstRepo.details.case === "githubRepoDetails") {
        handleTargetCategory({
          owner: firstRepo.details.value.owner,
          name: firstRepo.details.value.repo,
          displayName: firstRepo.displayName,
          branch: firstRepo.targetBranch,
        });
        dispatch(setTargetRepoID(firstRepo.id));
      }
    }
  }, [repos, handleTargetCategory, targetCategory, dispatch]);

  // Set title and read URL params
  useEffect(() => {
    document.title = "Projects - EdgeBit";
  }, []);

  return (
    <>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Proposals
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "0px" }}>
        Dependency updates proposed to fix security issues or keep libraries up to date.
      </Typography>

      <Container sx={{ mt: 3 }} disableGutters>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Proposals" {...a11yProps(0)} />
            <Tab label="Repositories" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={search === null ? "" : search}
            onChange={(e) => handleSearch(e)}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                handleSpecificPackageModalOpen("requested");
                ev.preventDefault();
              }
            }}
            size="small"
            sx={{ height: "40px", width: "320px", marginRight: "10px" }}
            placeholder="Search by package or vulnerability ID..."
            autoFocus={true}
          />

          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="Button group with a nested menu"
            disableElevation
            disableRipple
            sx={{ minHeight: "40px", "& .MuiButtonGroup-firstButton": { borderColor: "rgba(255,255,255,.6)" } }}
          >
            <EdgeBitPrimaryButton onClick={(event) => handleUpgradeCategoryModalOpen("requested")}>
              Analyze Upgrade
            </EdgeBitPrimaryButton>
            <EdgeBitPrimaryButton
              size="small"
              aria-controls={categoryMenuOpen ? "split-button-menu" : undefined}
              aria-expanded={categoryMenuOpen ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleOpenCateoryMenu}
            >
              <ArrowDropDownIcon />
            </EdgeBitPrimaryButton>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
              textAlign: "right",
            }}
            open={categoryMenuOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "right top" : "right bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseCategoryMenu}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      <MenuItem key="outdated" onClick={(event) => handleUpgradeCategoryModalOpen("outdated")}>
                        Upgrade an Outdated Package
                      </MenuItem>
                      <MenuItem
                        key="outdatedWithVulns"
                        onClick={(event) => handleUpgradeCategoryModalOpen("outdatedWithVulns")}
                      >
                        Fix a Vulnerable Package
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <PackageAutocomplete
            results={pkgSearchResults}
            repos={repos}
            issues={issueSearchResults}
            search={search}
            setTargetPkg={handleTargetPkg}
            setTargetIssue={handleIssuePkg}
          />

          <DialogTriggerPackageAnalysis />

          <DialogTriggerCategoryAnalysis />

          <TableContainer component={Paper} sx={{ display: "table", marginTop: "20px", border: "1px solid #ddd" }}>
            <ProposalTable proposals={proposals} repos={repos}></ProposalTable>
          </TableContainer>
        </TabPanel>

        <TabPanel value={value} index={1}>
          {projectId && <RepoList projectId={projectId}></RepoList>}
        </TabPanel>
      </Container>
    </>
  );
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ marginTop: "30px" }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
